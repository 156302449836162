
<template>
  <TopBar v-if="!isNFTRecentListRoute"
  :style="{ paddingLeft: isCollapsed ? `calc(${collapseSize} + 40px)` : `calc(${normalSize} + 40px)` }"/>
  <div class="container-fluid" v-if="!isNFTRecentListRoute">
    <div
      class="hamburger-menu-button"
      @click="toggleMenu"
    >
      <vue-feather
        type="menu"
        style="margin-right: 6px;"
      />
    </div>
    <div class="row">
      <div
        class="col left-menu"
        :class="{ 'menu-open': isMenuOpen }"
        :style="{ width: isCollapsed? collapseSize : normalSize, minWidth: isCollapsed ? '80px' : '270px' }"
        style="padding: 0;"
      >
        <div style="height: 140px;">
          <div class="app-logo" style="position: relative; height: 100%">
            <div class="app-logo" :style="{ marginLeft: isCollapsed? '-20px' : '0' }" style="padding: 0 10px 0 20px;">
              <a href="https://kryptofighters.io/">
                <img v-if="isCollapsed && !isMenuOpen"
                  class="mini-logo"
                  src="./assets/kf-mini-logo.png"
                  alt="App Icon"
                  style="height: 50px; padding-left: 15px;">
                <img v-else
                  class="full-logo"
                  src="./assets/kf-logo-new.png"
                  alt="App Icon"
                  style="height: 90px;">
              </a>
              
            </div>
            <button
              type="button"
              class="btn-close"
              style="justify-self: ;"
              @click="toggleMenu"
            />
            
            <div class="collapse-btn" style="">
                <div 
                :class="['collapse-trigger-toggle', { 'collapse-trigger-toggle-transform': isCollapsed }]" 
                @click="toggleCollapse()" >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="custom-icon"
                  height="24px"
                  viewBox="0 -960 960 960"
                  width="24px"
                >
                  <path d="m480-320 56-56-64-64h168v-80H472l64-64-56-56-160 160 160 160Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                </svg>
              </div>
            </div>
            
          </div>

          
        </div>

        <div
          class="menu-items"
          :style="{ padding: `0 16px 0 16px` }"
        >
          <router-link
            to="/marketplace"
            class="menu-item"
            :class="{ 'active': isCurrentRoute('marketplace') }"
          >
          <FontAwesomeIcon :icon="fas.faHouse" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Marketplace</div>
          </router-link>
          <router-link
            to="/game"
            class="menu-item"
            :class="{ 'active': isCurrentRoute('game') }"
          >
          <FontAwesomeIcon :icon="fas.faGamepad" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Game</div>
          </router-link>
          <a
            href="https://zealy.io/cw/kryptofighters/questboard"
            class="menu-item"
            target="_blank"
          >
          <FontAwesomeIcon :icon="fas.faParachuteBox" />

            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Airdrop</div>
          </a>
          <a
            href="javascript:void(0);"
            class="menu-item"
            @click="addKromaSepoliaNetwork"
          >
          <FontAwesomeIcon :icon="fas.faPlus" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Add Kroma Network</div>
          </a>
          <!-- <router-link
            to="/staking"
            class="menu-item"
          >
            <vue-feather
              class="menu-icon"
              type="dollar-sign"
              style=""
            />
            <div v-if="!isCollapsed || isMenuOpen">Staking</div>
          </router-link>
          <router-link
            to="/free_mint"
            class="menu-item-special"
          >
            <vue-feather
              type="zap"
              class="menu-icon-special menu-icon"
              style="margin-right: 6px;width: 20px;"
            />
            <div v-if="!isCollapsed || isMenuOpen">Free Mint</div>
          </router-link>
          
          <router-link
            to="/polygon-bridge"
            class="menu-item"
            :class="{ 'active': isCurrentRoute('polygon-bridge') }"
          >
          <FontAwesomeIcon :icon="fas.faNetworkWired" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Polygon Bridge</div>
          </router-link>-->
          <router-link
            to="/fighter-workshop"
            class="menu-item"
            :class="{ 'active': isCurrentRoute('fighter-workshop') }"
            style="gap: 5px; margin-top: 20px;"
          >
            <!-- <FontAwesomeIcon :icon="fas.faNetworkWired" /> -->
            <!-- <svg class="icon-menu-image" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="20px" style="margin-right: 6px;">
              <path d="M159-120v-120h124L181-574q-27-15-44.5-44T119-680q0-50 35-85t85-35q39 0 69.5 22.5T351-720h128v-40q0-17 11.5-28.5T519-800q9 0 17.5 4t14.5 12l68-64q9-9 21.5-11.5T665-856l156 72q12 6 16.5 17.5T837-744q-6 12-17.5 15.5T797-730l-144-66-94 88v56l94 86 144-66q11-5 23-1t17 15q6 12 1 23t-17 17l-156 74q-12 6-24.5 3.5T619-512l-68-64q-6 6-14.5 11t-17.5 5q-17 0-28.5-11.5T479-600v-40H351q-3 8-6.5 15t-9.5 15l200 370h144v120H159Zm80-520q17 0 28.5-11.5T279-680q0-17-11.5-28.5T239-720q-17 0-28.5 11.5T199-680q0 17 11.5 28.5T239-640Zm126 400h78L271-560h-4l98 320Zm78 0Z"/>
            </svg> -->
            <img class="icon-menu-image" :src="require(`@/assets/workshop/fighterworkshop-icon.png`)" alt="workshop-icon" style="width: 24px;" />
            <img v-if="!isCollapsed || isMenuOpen" class="icon-menu-image" :src="require(`@/assets/workshop/fighterworkshop-text.png`)" alt="workshop-text" style="height: 24px;" />
            <!-- <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Fighter Workshop</div> -->
          </router-link>


          <a
            href="https://medium.com/@KryptoFightersOfficial"
            class="menu-item"
            style="margin-top: auto;"
            target="_blank"
          >
          <FontAwesomeIcon :icon="fas.faBook" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Blogs</div>
          </a>
          <a
            href="https://twitter.com/KryptoFighters"
            class="menu-item"
            target="_blank"
          >
          <FontAwesomeIcon :icon="fab.faTwitter" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Follow Us</div>
          </a>
          <a
            href="https://discord.com/servers/krypto-fighters-official-discord-488176442673332224"
            class="menu-item"
            target="_blank"
          >
          <FontAwesomeIcon :icon="fab.faDiscord" />
            <div v-if="!isCollapsed || isMenuOpen" style="margin-left: 8px;">Community</div>
          </a>
        </div>
      </div>

      <div
        class="content"
        :style="{ paddingLeft: isCollapsed ? collapseSize : normalSize }"
      >
      <div class="m-page-wrapper" >
        <main class="m-main-content">
          <router-view />
        </main>
        <Footer />
      </div>
      
      </div>
    </div>
    <div v-if="!isCookieConsent"  style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: red">
    <div class="m-page-wrapper cookies-banner">
        <p>
          Krypto Fighters uses cookies and similar technologies to provide you with personalized content, 
          improve site performance, and conduct analytics. By using the Krypto Fighters website or other online services, 
          you consent to the practices described in our <u>Privacy Policy</u> and <u>Terms of Service</u>.
        </p>
        <div class="d-flex justify-content-center align-items-center cookies-banner-btn" style="">
          <button class="btn btn-primary btn-cookies" @click="acceptCookies">Accept</button>
          <button class="btn btn-secondary btn-cookies" @click="declineCookies">Reject</button>
        </div>
      </div>
      </div>
  </div>
  <router-view v-if="isNFTRecentListRoute" />

</template>

<script setup>
import { ref,computed, onMounted } from 'vue';
import TopBar from '@/components/TopBar.vue';
import Footer from '@/components/Footer.vue';
import { useRoute } from 'vue-router';
import {useToast} from 'vue-toast-notification';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas} from '@fortawesome/free-solid-svg-icons'
import { fab} from '@fortawesome/free-brands-svg-icons'
import Cookies from 'js-cookie';

const isCookieConsent = ref(Cookies.get('cookieConsent') === 'true');

const acceptCookies = () => {
  Cookies.set('cookieConsent', 'true', { expires: 365 });
  isCookieConsent.value = true;
};

const declineCookies = () => {
  // window.location.href = '/privacy-policy';
  isCookieConsent.value = true;
};

const computedContentStyle = computed(() => {
      // Check if the window width is greater than 768px
      const isPC = window.innerWidth > 768;
      return isPC
        ? {
            // width: isCollapsed.value ? `calc(100% - ${collapseSize})` : `calc(100% - ${normalSize})`,
            paddingLeft: isCollapsed.value ? collapseSize : normalSize,
          }
        : {}; // Return an empty object for mobile
    });


const isCurrentRoute = (routePath) => {
  const route = useRoute();
  if(route === undefined)
    return false;
  console.log(route.meta);
  console.log("routePath: " + routePath);
  console.log("Comparison result: " + (route.name === routePath)); // Corrected comparison
  return (route.name === routePath);
};
const isMenuOpen = ref(false);

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
};

const isNFTRecentListRoute = computed(() => {
  return useRoute()?.path === '/iframe/nft-recent-list';
});

const addKromaSepoliaNetwork = async () => {
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: '0xFF',
        chainName: 'Kroma Testnet',
        rpcUrls: ['https://api.kroma.network/'],
        nativeCurrency: {
          name: 'Kroma ETH',
          symbol: 'ETH',
          decimals: 18
        },
        blockExplorerUrls: ['https://kromascan.com/']
      }]
    });
    useToast().open({
      message: 'Kroma Network added successfully.',
      position: 'top-right',
      type: 'success',
    });
  } catch (error) {
    console.error('Error adding network:', error);
    useToast().open({
      message: 'Error adding Kroma Network.',
      position: 'top-right',
      type: 'error',
    });
  }
};

const isCollapsed = ref(false);

onMounted(() => {

    const storedState = localStorage.getItem('isCollapsed');
    if (storedState !== null) {
        isCollapsed.value = JSON.parse(storedState);
    }
   
});

const toggleCollapse = () => {
    isCollapsed.value = !isCollapsed.value;
    // Save the current state to localStorage
    localStorage.setItem('isCollapsed', JSON.stringify(isCollapsed.value));
    console.log("isCollapsed: " + isCollapsed.value);
};

const collapseSize = '80px';
const normalSize = '270px';
</script>

<style scoped>

.m-page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);/* Make sure the container takes at least the full height of the viewport */
}

.m-main-content {
  flex: 1; /* This makes sure the main content area takes up all available space */
}

.left-menu {
  width: 270px;
  /* Fixed width for the left menu */
  height: 100vh;
  background-color: var(--primary-background-color);
  /* padding-left: 20px; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: width 0.5s ease, min-width 0.2s ease
  /* overflow-y: auto; */
  /* Add vertical scroll if needed */
}

.content {
  padding: 0;
  padding-left: 17%;
  /* Use margin */
  margin-top: 38px !important;
  /* Adjust as needed */
  width: calc(100%);
  margin: 0;
  /* Calculate width minus the margin */
}

.btn-close {
  display: none;
}

.elementor-inline-items {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.elementor-inline-item {
    margin-right: calc(28px / 2);
    margin-left: calc(28px / 2);
}

.elementor-icon-list-text {
    font-family: 'Rajdhani', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.elementor-inline-item a {
    box-shadow: none;
    text-decoration: none;
}

ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0;
}

.elementor-icon-list-items {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
  display: flex; /* Align list items horizontally */
}

/* Style for individual list items */
.elementor-icon-list-item {
  position: relative; /* Ensure pseudo-element positioning is relative to this item */
  margin-right: 20px; /* Space between items, adjust as needed */
}

/* Add separator line between list items except the last one */
.elementor-icon-list-item:not(:last-child)::after {
  content: "";
  position: absolute;
  height: 12px; /* Adjust height as needed */
  border-left: 2px solid #ddd; /* Set color and width of the separator line */
  right: -10px; /* Adjust right positioning to align with the item */
  top: 50%; /* Center the line vertically */
  transform: translateY(-50%); /* Center the line vertically */
  right: calc(-35px / 2);
}

/* Ensure the links inside list items are styled as needed */
.elementor-icon-list-item a {
  text-decoration: none; /* Remove underline from links */
  color: inherit; /* Inherit text color from parent */
}

.elementor-heading-title {
    color: var(--e-global-color-text);
    font-family: "Rajdhani", Sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}

.collapse-trigger-toggle {
  transition: transform 0.3s;
}

.collapse-trigger-toggle.collapse-trigger-toggle-transform {
  transform: rotate(180deg);
}

.collapse-btn {
  position: absolute; 
  right: 0; 
  transform: translateX(50%); 
  bottom: 60px;
}

.mobile-logo {
  display: block;
}

.desktop-logo {
  display: block;
}

.cookies-banner {
  justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    /* width: 100%; */
    background-color: var(--light-background-color);
    min-height: auto;
    padding: 30px 40px;
    z-index: 3;

    max-width: 800px;

}

.btn-cookies {
  width: 10%;
  min-width: 140px;
}

.cookies-banner-btn {
  gap: 30px;
  width: 100%;
}

/* Show/hide menu based on screen width */
@media (max-width: 768px) {
  .left-menu {
    width: 0px !important;
    min-width: 0px !important;
    transition: width 0.3s ease;
    overflow: hidden;
    opacity: 0;
  }

  .menu-open {
    width: 260px !important;
    opacity: 1;
    /* Set your desired menu width */
  }

  .hamburger-menu-button {
    display: block;
    /* Show the button on smaller screens */
    position: fixed;
    top: 10px;
    left: 0px;
    width: 40px;
    margin-left: 20px;
    margin-top: 6px;
    cursor: pointer;
    z-index: 2;
  }

  .hamburger-icon {
    display: block;
    width: 30px;
    height: 3px;
    background-color: #fff;
    /* Hamburger icon color */
    margin: 6px 0;
    transition: 0.4s;
  }

  .menu-open .hamburger-icon {
    background-color: transparent;
    /* Change to transparent when menu is open */
  }

  .content {
    /* margin-left: 0 !important;*/
    margin-top: 68px !important; 
    padding-left: 0 !important;
    padding: 0;
    width: 100%;
    min-height: 100vh;
  }

  .app-logo {
    display: inline-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .btn-close {
    display: inline-block;
  }

  .collapse-btn {
    display: none;
  }

  .mini-logo {
    display: none;
  }

  .full-logo {
    display: block;
    padding-left: 14px;
  }

  .cookies-banner {
      padding: 20px 40px;
  }

  .btn-cookies {
    width: 100%;
    min-height: auto
  }

  .cookies-banner-btn {
    gap: 20px;
  }

}

.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  overflow-y: auto;
}

.app-logo {
  display: flex;
  /* Use flexbox to align items */
  justify-content: space-between;
  align-items: center;
  /* Center items vertically within .app-logo */
}

.app-logo img {
  height: 140px;
  /* Set the height of the app icon */
  /*margin: 20px;*/
  /* Center the app icon horizontally with some top margin */
  display: block;
}

.list-group-item {
  transition: background-color 0.3s ease;
  /* Add transition for the 'background-color' property */
}

.list-group-item:hover {
  background-color: #555d66;
  /* Change the background color on hover */
}

.menu-item {

  padding: 8px 13px;
  display: flex;
  color: var(--secondary-text-color);
  /* Set text color to white */
  text-decoration: none;
  /* Remove underline */
  transition: background-color 0.3s ease;
  border-radius: 6px;
  align-items: center;
  height: 45px;
  /* fill: var(--secondary-text-color); */
}

.menu-item:hover {
  background-color: var(--soft-background-color);
  border-radius: 6px;
  color: var(--secondary-color);
  /* fill: var(--secondary-color); */
}

.menu-item.active {

  background-color: var(--secondary-background-color);
  border-radius: 6px;
  color: var(--secondary-color);
  /* fill: var(--secondary-color); */
}

.menu-item:hover .icon-menu-image,
.menu-item.active .icon-menu-image {
  /* fill: var(--secondary-color); */
  filter: brightness(.2) saturate(100%) invert(30%) sepia(100%) saturate(700%) hue-rotate(230deg) brightness(100%) contrast(70%);
}


.menu-item-special {
  padding: 10px 15px;
  color: #ffd70d;
  font-weight: bold;
  border-radius: 8px;
  border-width: 0;
  display: inline-flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease, all 0.5s ease;
  margin: 0;
  font-size: medium;
  margin: 5px 0 5px 0;
}

.menu-item-special:hover {
  background-color: darkgoldenrod;
}

.menu-item-special.active  {
  background-color: var(--soft-background-color);

}

.menu-item-special:hover:after {
  content: '';
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 220px;
  position: absolute;
  z-index: 1;
  animation: slide 2s infinite;
  
  background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0)));
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
}

@keyframes slide {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(100%); }
}

.custom-icon {
  fill: var(--secondary-text-color); /* Default color */
  transition: fill 0.3s ease; /* Smooth transition for hover */
}

.custom-icon:hover {
  fill: #ffffff; /* Change to white on hover */
}

.menu-icon {
  
  width: 20px; 
  padding: 2px 0;
}

.menu-icon-special{
  filter: drop-shadow(1px 1px 0.5px #3e2723) drop-shadow(0 0 5px #3e2723);
}

.vertical-align-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

.icon-menu-image {
  fill: #e8eaed; 
  filter: unset;
}
</style>